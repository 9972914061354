import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Mp3File from '../../../../assets/audio/file_audio.mp3';
import BImage from '../../../../assets/images/varlorcol_desktop.png';
import QuoteMark from '../../../../Atoms/QuoteMark/QuoteMark';
import ArticleHighlight from '../../../../components/ArticleHighlight/ArticleHighlight';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../../components/Carousel/Carousel';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import Seo from '../../../../components/Seo/Seo';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import Layout from '../../../../Layout';
import { Button } from '../../../../Molecules/Button/Button';
import MemberProfileBlock from '../../../../Molecules/MemberProfileBlock/MemberProfileBlock';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';

import './styles.scss';

let classNames = require('classnames');

const RoutePage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "history.png"}) {
        childImageSharp {
          mobile: fixed(width: 456, height: 456) {
            ...GatsbyImageSharpFixed
          }
          desktop: fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const slider_settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '100px',
    slidesToShow: 1.5,
    speed: 500,
    initialSlide: 0,
    arrows: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1.05,
        dots: true
      }
    }]
  };

  let slides = [
    {
      image: data.file,
      info: {
        text: 'title text',
        link: '/',
        link_label: 'Afficher'
      },
      reading_time: '2\'31\'\'',
    },
    {
      image: data.file,
      info: {
        text: 'title text',
        link: '/',
        link_label: 'Afficher'
      },
      reading_time: '2\'31\'\'',
    },
    {
      image: data.file,
      info: {
        text: 'title text',
        link: '/',
        link_label: 'Afficher ...'
      },
      reading_time: '2\'31\'\'',
    },
    {
      image: data.file,
      info: {
        text: 'title text',
        link: '/',
        link_label: 'Afficher'
      },
      reading_time: '2\'31\'\'',
    }
  ];
  slides.push(...slides); //make just double for inifinite carousel

  return (
    <Layout>
      <Seo
        title="Colas - Réunion des analystes"
        description="Description de la page Réunion des analystes"
      />
      <div className={classNames('page_template', 'activities_inner_page')}>
        <PageBannerStyle
          visuel={data.file.childImageSharp.desktop} scroll={true}
          breadcrumbData={{
            grandParentPage: { title: 'Acceuil', url: '/' },
            parentPage: { title: 'Finance', url: '/' },
            currentPage: { title: 'Réunion des analystes', url: '/finance/analyst-meeting' }
          }}
        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title="Comité direction"
            />
            <div className="col_2_fix">
              <div className="col">
                <p className="bold">Cold Asphalt : c’est pour exploiter ce brevet d’émulsion de bitume qu’est né Colas. Une innovation de rupture qui a révolutionné la technique routière. Cœur de métier historique du Groupe, la construction et l’entretien d’infrastructures routières constituent l’activité principale de Colas.</p>
              </div>
              <div className="col">
                <p>Sûre, durable, bas carbone, recyclée, accessible, partagée, fluide, connectée… La route de demain représente un XXXXXXXXXX pour Colas, qui se positionne progressivement comme un intégrateur global de solutions de mobilité responsable, tout particulièrement dans la perspective de la ville durable. En 2020, l’activité représente un chiffre d'affaires de 8,9 milliards d'euros, soit 72% du chiffre d’affaires total du Groupe. Très diversifiée, la Route bénéficie d’une large gamme de métiers et de savoir-faire.</p>
              </div>
            </div>
            <AudioComponent
              text="ÉCOUTER LA VERSION AUDIO"
              textToRead="Cold Asphalt : c’est pour exploiter ce brevet d’émulsion de bitume qu’est né Colas. Une innovation de rupture qui a révolutionné la technique routière. Cœur de métier historique du Groupe, la construction et l’entretien d’infrastructures routières constituent l’activité principale de Colas. Sûre, durable, bas carbone, recyclée, accessible, partagée, fluide, connectée… La route de demain représente un XXXXXXXXXX pour Colas, qui se positionne progressivement comme un intégrateur global de solutions de mobilité responsable, tout particulièrement dans la perspective de la ville durable. En 2020, l’activité représente un chiffre d'affaires de 8,9 milliards d'euros, soit 72% du chiffre d’affaires total du Groupe. Très diversifiée, la Route bénéficie d’une large gamme de métiers et de savoir-faire."
            />
          </div>
        </PageBannerStyle>
        <section className={classNames('section_content', 'section_diverse_activities')}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="Une activité diversifiée"
            />
            <TextVisuelTwoCol
              orderInverse={true}
              extremeRight={true}
              extactHalf={true}
              alignLeft={true}
              visuel={data.file.childImageSharp.desktop.fluid}
              text="Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
            <div className="quotemark_container">
              <QuoteMark
                lang="fr"
                size="m"
                textWeight="m"
                text="Colas ouvre la voie vers un monde plus responsable !"
              />
            </div>
          </div>
        </section>
        <section className={classNames('section_content', 'section_grand_projects')}>
          <div className="wrapper_page_xs">
            <TitleSection
              title="Les grands projets routiers"
            />
            <TextVisuelTwoCol
              orderInverse={false}
              extremeLeft={true}
              extactHalf={true}
              alignTop={true}
              visuel={data.file.childImageSharp.desktop.fluid}
              text="Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
          </div>
        </section>
        <section className={classNames('section_content', 'section_our_realisation')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="wrapper_page_xs">
              <TitleSection
                title="Nos réalisations"
                subtitle="Les actualités du groupe Colas, à travers le monde"
                type="arrow"
              />
            </div>
            <div className={classNames('wrapper_page', 'only_desktop')}>
              <Carousel slides={slides} />
            </div>

            <div className={classNames('only_mobile_tablet')}>
              <SliderComponent settings={slider_settings}>
                {slides.map((slide, i) => (
                  <div key={i}>
                    <div className="only_mobile_tablet">
                      {slide?.image &&
                        <Img
                          style={{ width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          fluid={{ ...slide.image.childImageSharp.mobile, aspectRatio: 0.733333333 }}
                        />
                      }
                    </div>
                    <div className="only_desktop">
                      {slide?.image &&
                        <Img
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'contain' }}
                          fluid={slide.image.childImageSharp.desktop.fluid}
                        />
                      }
                    </div>
                    <div className="info_bleu_box">
                      <p>{slide.info.text}</p>
                      {slide.info.link && (
                        <Button
                          primary={true}
                          label={slide.info.link_label}
                          link={slide.info.link}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </SliderComponent>
            </div>
          </ShapeBackground>
        </section>
      </div>
    </Layout>
  );
};

export default RoutePage;
